<template>
	<div class="page container">
		<div class="page__info">
			<div class="page__info--title">
				Teaching
			</div>
			<div class="page__info--subtitle">
				Enhance your teaching techniques, inspire your students.
			</div>
		</div>
		<div class="separator" />
		<div class="content teaching">
			<div class="main">
				<div class="teaching--links">
					<router-link
						v-for="link in list"
						:key="link.route"
						:to="link.route"
						class="teaching--links__item"
					>
						{{ link.text }}
						<icon icon="v2-chevron-left" colour="transparent" />
					</router-link>
				</div>
			</div>
			<div class="side">
				<div class="teaching--teacher-title">
					<icon icon="v2-teacher" colour="transparent" />
					Your Profile
				</div>
				<div class="teaching--teacher teacher">
					<img class="teacher__avatar" :src="getImageSrc" />
					<div class="teacher__text">
						<div class="teacher__text--title">
							{{ getFullName }}
						</div>
						<div class="teacher__text--subtitle">
							Teacher
						</div>

						<div class="separator" />

						<div class="teacher__text--info">
							Language: <span class="bold">{{ getLanguage }}</span>
						</div>
						<div class="teacher__text--info">
							Country: <span class="bold">{{ getCountry }}</span>
						</div>

						<router-link class="teacher__text--btn" :to="`/teachers/${getTeacherId}`">
							Edit Profile
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>
	import { languages }       from 'countries-list';
	import { countries }       from 'countries-list';
	import Icon           from '@/components/ui/Icon';
	import MockImage from '@/assets/icons/v2/image-placeholder-small.svg';
	import api                from '@/services/api';

	export default {
		metaInfo: {
			title: 'Teaching'
		},

		layout: 'v2default',

		components: {
			Icon
		},

		data () {
			return {
				teacherProfile: null
			};
		},

		computed: {

			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					}
				];
			},

			getImageSrc () {
				if (!this.teacherProfile?.media?.[0]?.url) {
					return MockImage;
				}
				return this.teacherProfile.media[0].url;
			},

			getProfile () {
				return this.$store.getters['auth/getProfile'];
			},

			getFullName () {
				return this.$store.getters['auth/getFullName'];
			},

			getTeacherId () {
				return this.$store.getters['auth/getTeacherId'];
			},

			getLanguage () {
        if (!this.getProfile?.language) {
          return '';
        }
				return languages[this.getProfile.language].name;
			},

			getCountry () {
				return countries[this.getProfile.country].name;
			},

			list () {
				return [
					{
						text: 'See Courses',
						route: '/teaching/courses'
					},
					{
						text: 'See Deal Library',
						route: '/teaching/deals'
					},
					{
						text: 'Payment Settings',
						route: '/teaching/payment'
					},
					{
						text: 'Transactions',
						route: '/teaching/transactions'
					},
					{
						text: 'Students',
						route: '/teaching/students'
					},
					{
						text: 'Options',
						route: '/teaching/options'
					}
				];
			}
		},
		async created () {
			this.$store.commit('ui/setLoadingIsHidden');

			const teacherProfile = await api.teacherProfiles.getTeacherProfileById({
				profileId: this.getTeacherId
			});

        if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}

			this.$store.commit('ui/setBreadcrumbs', {
				breadcrumbs: this.getBreadcrumbs
			});

			this.teacherProfile = teacherProfile;
		}
	};

</script>

<style lang="scss" scoped>

.teaching {

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .main, .side {
      width: 100%;
    }
  }

  &--teacher-title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 10px;
    @include font(18px, $c-darkest-navy, 700, 23px);
  }

  &--teacher {
    display: flex;
    padding: 12px;
    border: 1px solid $c-gray-d2;
    gap: 12px;

    .teacher {
      &__avatar {
        width: 45px;
        height: 45px;
        object-fit: cover;
        border-radius: 2px;
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .separator {
          margin: 12px 0
        }

        &--title {
          @include font(18px, $c-dark-navy, 500, 23px);
        }

        &--subtitle {
          @include font(14px, $c-medium-navy, 700, 18px);

          ::v-deep [data-element='introduction'] {
            font-size: rem(14);
            @include rich-text-content;
            @include rich-text-suits;
          }
        }

        &--info {
          @include font(16px, $c-dark-navy, 400, 20px);

          &:not(:last-child) {
            margin-bottom: 4px;
          }

          .bold {
            font-weight: 700;
          }
        }

        &--btn {
          margin-top: 12px;
          @include font(16px, $c-dark-navy, 500, 20px);
          width: 100%;
          padding: 8px 20px;
          border-radius: 4px;
          border: 1px solid $c-dark-navy;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &--links {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      @include font(18px, $c-darkest-navy, 400, 23px);

      svg {
        transform: rotate(180deg);
      }

      &:not(:last-child) {
        border-bottom: 1px solid $c-white-d2;
      }

      &:first-child {
        padding-top: 0;
      }
    }
  }
}

</style>
